import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import { graphql } from 'gatsby';
import styled from 'styled-components';

const HeaderBox = styled.div`
  display:flex;
  & > svg {
    flex: 1;
  }
  & > p {
    flex: 9;
    margin-top: 2.5rem;
  }
`;

const Index = ({ data }) => (
  <Layout>
    <Box>
      <HeaderBox style={{ }}>
        <Title as="p" size="larger">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
        </Title>
      </HeaderBox>
    </Box>
    {
        data.homeJson.gallery.map( g => (
          <Box key={g.title}>
            <Title as="h2">{g.title}</Title>
            <p key={'x-' + g.title}>{g.copy}</p>
          </Box>
        ))
    }
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        # image {
        #   childImageSharp {
        #     fluid(maxHeight: 500, quality: 90) {
        #       ...GatsbyImageSharpFluid_withWebp
        #     }
        #   }
        # }
      }
    }
  }
`;
