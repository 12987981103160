import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

// const HeaderBox = styled.div`
//   display:flex;
//   & > svg {
//     flex: 1;
//   }
//   & > p {
//     flex: 9;
//     margin-top: 2.5rem;
//   }
// `;



const Header = ({ title }) => (
  <AnimatedContainer>
    <Container>
      <svg viewBox="0 0 20 20">
        <ellipse
          cx="10"
          cy="10"
          rx="7.5"
          ry="5"
          fill="black"
        />
        <ellipse
          cx="5.2" cy="13.25"
          rx="4"
          ry="5"
          fill="white"
          transform="rotate(-24)"
        />
      </svg>
      <Link to="/">
        <Title as="h1">{title}</Title>
      </Link>
      <Nav />
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
